<template>
  <base-section id="theme-features">
    <base-section-heading title="TokenX Features"> </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            customText="Features"
          >
            -----
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "TokenxMoreFeatures",

  data: () => ({
    features: [
      {
        title: "Fast Compliance",
        image: "compliant",
        text: "Achieve compliance with industry standards like GDPR, PCI, CCPA, etc. quickly and efficiently.",
      },
      {
        title: "Innovation and Scalability",
        image: "innovation",
        text: "Streamline operations to allow companies to focus on innovation and scaling their business.",
      },
      {
        title: "Developer-Friendly API",
        image: "web-development",
        text: "Implement the solution swiftly and easily through an elegant and developer-friendly API.",
      },
      {
        title: "Secure Integration",
        image: "honesty",
        text: "Seamlessly integrate with third-party services while maintaining data security.",
      },
      {
        title: "Cost-Effective Compliance",
        image: "checklist",
        text: "Attain data security standards and compliances at a fraction of the cost required for in-house development.",
      },
      {
        title: "Tokenization and Detokenization",
        image: "currency",
        text: "Safeguard sensitive data through tokenization and detokenization processes.",
      },
      {
        title: "Secure Data Storage",
        image: "secure-data",
        text: "Utilize a 'Zero-Trust, Zero-Knowledge' architecture for secure storage and utilization of sensitive data.",
      },
      {
        title: "Automated DSR Fulfillment",
        image: "automation",
        text: "Automate the fulfillment of data subject requests for enhanced efficiency.",
      },
      {
        title: "Encryption and Key Management",
        image: "encryption",
        text: "Ensure secure data storage and access through encryption and key management.",
      },
      {
        title: "API and SDK Integration",
        image: "api",
        text: "Seamlessly integrate with existing software systems using APIs and SDKs.",
      },
      {
        title: "Data Masking",
        image: "folder",
        text: "Protect sensitive data by implementing data masking techniques for display purposes.",
      },
      {
        title: "Reporting and Auditing",
        image: "audit",
        text: "Monitor tokenization and detokenization activities through comprehensive reporting and auditing.",
      },
      {
        title: "Data Governance Tools",
        image: "government",
        text: "Manage access and permissions to sensitive data through data governance tools.",
      },
      {
        title: "Complete Token Lifecycle Control",
        image: "integration",
        text: "Maintain full control over the token lifecycle, including creation, deletion, validation, and updating.",
      },
    ],
  }),
};
</script>
